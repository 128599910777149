:global(.st_row).row_nowrap {
  flex-wrap: nowrap;
}

:global(.st_row).row_wrap {
  flex-wrap: wrap;
}

.section {
  margin-right: var(--st-spacer-mlg);
}

.search__caret__icon {
  margin-left: var(--st-spacer-xs);
}

input.filter_cb_exclude:checked:after {
  /* content: "✗"; */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill ='%23fff' d='M8.943 8l3.529 3.529c0.26 0.26 0.26 0.682 0 0.943s-0.682 0.26-0.943 0l-3.529-3.529-3.529 3.529c-0.26 0.26-0.682 0.26-0.943 0s-0.26-0.682 0-0.943l3.529-3.529-3.529-3.529c-0.26-0.26-0.26-0.682 0-0.943s0.682-0.26 0.943 0l3.529 3.529 3.529-3.529c0.26-0.26 0.682-0.26 0.943 0s0.26 0.682 0 0.943z' /%3E%3C/svg%3E");
}
