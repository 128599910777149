.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--st-border-color);
}

.wrapper--fill {
  width: 100%;
  height: 100%;
  position: absolute;
}

.breakword {
  overflow: hidden;
  overflow-wrap: anywhere;
}

.transparent_bg {
  background-image: linear-gradient(45deg, var(--st-color-asphalt) 25%, transparent 25%), linear-gradient(-45deg, var(--st-color-asphalt) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--st-color-asphalt) 75%), linear-gradient(-45deg, transparent 75%, var(--st-color-asphalt) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  background-color: var(--st-color-gray);
}

.image {
  animation: fadeIn ease 1s;
  border-radius: var(--st-border-radius);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9JbWFnZS9JbWFnZS5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtFQUdBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0lBQUk7O0VBQ0o7SUFBTSIsInNvdXJjZXNDb250ZW50IjpbIi53cmFwcGVyIHtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tc3QtYm9yZGVyLWNvbG9yKTtcbn1cblxuLndyYXBwZXItLWZpbGwge1xuICB3aWR0aDogMTAwJTtcbiAgaGVpZ2h0OiAxMDAlO1xuICBwb3NpdGlvbjogYWJzb2x1dGU7XG59XG5cbi5icmVha3dvcmQge1xuICBvdmVyZmxvdzogaGlkZGVuO1xuICBvdmVyZmxvdy13cmFwOiBhbnl3aGVyZTtcbn1cblxuLnRyYW5zcGFyZW50X2JnIHtcbiAgYmFja2dyb3VuZC1pbWFnZTogbGluZWFyLWdyYWRpZW50KDQ1ZGVnLCB2YXIoLS1zdC1jb2xvci1hc3BoYWx0KSAyNSUsIHRyYW5zcGFyZW50IDI1JSksXG4gIGxpbmVhci1ncmFkaWVudCgtNDVkZWcsIHZhcigtLXN0LWNvbG9yLWFzcGhhbHQpIDI1JSwgdHJhbnNwYXJlbnQgMjUlKSwgbGluZWFyLWdyYWRpZW50KDQ1ZGVnLCB0cmFuc3BhcmVudCA3NSUsIHZhcigtLXN0LWNvbG9yLWFzcGhhbHQpIDc1JSksXG4gIGxpbmVhci1ncmFkaWVudCgtNDVkZWcsIHRyYW5zcGFyZW50IDc1JSwgdmFyKC0tc3QtY29sb3ItYXNwaGFsdCkgNzUlKTtcbiAgYmFja2dyb3VuZC1zaXplOiAyMHB4IDIwcHg7XG4gIGJhY2tncm91bmQtcG9zaXRpb246IDAgMCwgMCAxMHB4LCAxMHB4IC0xMHB4LCAtMTBweCAwcHg7XG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXN0LWNvbG9yLWdyYXkpXG59XG5cbi5pbWFnZSB7XG4gIGFuaW1hdGlvbjogZmFkZUluIGVhc2UgMXM7XG4gIGJvcmRlci1yYWRpdXM6IHZhcigtLXN0LWJvcmRlci1yYWRpdXMpO1xufVxuXG5Aa2V5ZnJhbWVzIGZhZGVJbiB7XG4gIDAlIHtvcGFjaXR5OjA7fVxuICAxMDAlIHtvcGFjaXR5OjE7fVxufSJdfQ== */