.position {
  position: relative;
}

.message {
  right: 0;
  top: var(--st-spacer-md);
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-sm);
  color: var(--st-color-white);
  padding: var(--st-spacer-xs) var(--st-spacer-sm);
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  text-wrap: nowrap;
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9Db3B5VG9DbGlwYm9hcmQvQ29weVRvQ2xpcGJvYXJkLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIucG9zaXRpb24ge1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG59XG5cbi5tZXNzYWdlIHtcbiAgcmlnaHQ6IDA7XG4gIHRvcDogdmFyKC0tc3Qtc3BhY2VyLW1kKTtcbiAgZm9udC1mYW1pbHk6IHZhcigtLXN0LWZvbnQtc3RhY2spO1xuICBmb250LXNpemU6IHZhcigtLXN0LWZvbnQtc2l6ZS1zbSk7XG4gIGNvbG9yOiB2YXIoLS1zdC1jb2xvci13aGl0ZSk7XG4gIHBhZGRpbmc6IHZhcigtLXN0LXNwYWNlci14cykgdmFyKC0tc3Qtc3BhY2VyLXNtKTtcbiAgYmFja2dyb3VuZC1jb2xvcjogcmdiYSgwLCAwLCAwLCAwLjgpO1xuICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gIHRleHQtd3JhcDogbm93cmFwO1xufVxuIl19 */