.listItem > div {
  width: var(--st-header-height);
  height: var(--st-header-height);
  border-radius: var(--st-border-radius);
}

.groupHover:hover {
    background-color: var(--st-content-bg);
}

