.input_file::file-selector-button {
  height: var(--st-spacer-mlg);
  padding: 0 var(--st-spacer-sm);
  background: var(--st-btn-secondary-bg);
  border: 1px solid var(--st-btn-secondary-color);
  color: var(--st-btn-secondary-color);
  justify-content: center;
  display: inline-flex;
  align-items: center;
  box-shadow: none;
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  font-weight: var(--st-font-weight-med);
  border-radius: var(--st-border-radius);
  cursor: pointer;
  text-decoration: none;
  transition: all 400ms;
  box-sizing: border-box;
  white-space: nowrap;
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvcm91dGVzL2RldmVsb3Blci9hcGkvY29tcG9uZW50cy91cGxvYWQvc3R5bGVzLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5pbnB1dF9maWxlOjpmaWxlLXNlbGVjdG9yLWJ1dHRvbiB7XG4gIGhlaWdodDogdmFyKC0tc3Qtc3BhY2VyLW1sZyk7XG4gIHBhZGRpbmc6IDAgdmFyKC0tc3Qtc3BhY2VyLXNtKTtcbiAgYmFja2dyb3VuZDogdmFyKC0tc3QtYnRuLXNlY29uZGFyeS1iZyk7XG4gIGJvcmRlcjogMXB4IHNvbGlkIHZhcigtLXN0LWJ0bi1zZWNvbmRhcnktY29sb3IpO1xuICBjb2xvcjogdmFyKC0tc3QtYnRuLXNlY29uZGFyeS1jb2xvcik7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBkaXNwbGF5OiBpbmxpbmUtZmxleDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgYm94LXNoYWRvdzogbm9uZTtcbiAgZm9udC1mYW1pbHk6IHZhcigtLXN0LWZvbnQtc3RhY2spO1xuICBmb250LXNpemU6IHZhcigtLXN0LWZvbnQtc2l6ZS1tZCk7XG4gIGZvbnQtd2VpZ2h0OiB2YXIoLS1zdC1mb250LXdlaWdodC1tZWQpO1xuICBib3JkZXItcmFkaXVzOiB2YXIoLS1zdC1ib3JkZXItcmFkaXVzKTtcbiAgY3Vyc29yOiBwb2ludGVyO1xuICB0ZXh0LWRlY29yYXRpb246IG5vbmU7XG4gIHRyYW5zaXRpb246IGFsbCA0MDBtcztcbiAgYm94LXNpemluZzogYm9yZGVyLWJveDtcbiAgd2hpdGUtc3BhY2U6IG5vd3JhcDtcbn1cbiJdfQ== */