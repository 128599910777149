.image {
  background-color: var(--st-color-char);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 530px; /* hard-coded so page doesn't jump */
  position: relative;
  border-color: var(--st-border-color);
  border-style: solid;
  border-width: 0 1px 1px 1px;
}

.image .focal_image {
  max-width: 100%;
  max-height: 530px;
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9FZGl0SW1hZ2UvQ29udGVudC9Db250ZW50Lm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIlxuLmltYWdlIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tc3QtY29sb3ItY2hhcik7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBoZWlnaHQ6IDUzMHB4OyAvKiBoYXJkLWNvZGVkIHNvIHBhZ2UgZG9lc24ndCBqdW1wICovXG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgYm9yZGVyLWNvbG9yOiB2YXIoLS1zdC1ib3JkZXItY29sb3IpO1xuICBib3JkZXItc3R5bGU6IHNvbGlkO1xuICBib3JkZXItd2lkdGg6IDAgMXB4IDFweCAxcHg7XG59XG5cbi5pbWFnZSAuZm9jYWxfaW1hZ2Uge1xuICBtYXgtd2lkdGg6IDEwMCU7XG4gIG1heC1oZWlnaHQ6IDUzMHB4O1xufVxuIl19 */