.imagecard {
  display: flex;
  position: relative;
  width: 100%;
}

.imagecard--small {
  margin-bottom: var(--st-spacer-md);
  flex-direction: column;
}

.imagecard--large {
  flex-direction: row;
}

.imagecard--selected {
  border: 3px solid var(--st-color-blurple-50);
  padding: calc(var(--st-spacer-md) - 3px + var(--st-border-width));
}

.imagecard__tokens {
  position: absolute;
  left: 0;
  padding: inherit;
  top: 0;
  z-index: var(--st-zindex-minor);
}

.imagecard__tokens button:global(.st_btn.st_btn--tertiary) {
  background: var(--st-content-bg);
}
.imagecard__close_button {
  background: var(--st-content-bg);
  border-radius: var(--st-border-radius);
  padding: inherit;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--st-zindex-minor);
}

.imagecard__imgplacer {
  position: relative;
}

.imagecard__imgplacer--large {
  height: 0%;
  min-width: 340px;
  max-width: 340px;
}

.imagecard__img {
  border-radius: var(--st-border-radius);
  object-fit: contain;
}

.imagecard__text_wrapper {
  box-sizing: border-box;
  width: 100%;
  padding-left: var(--st-spacer-md);
}

.imagecard__text {
  height: 100%;
  justify-content: space-between;
}

.visible {
  visibility: inherit;
}

.invisible {
  visibility: hidden;
}
