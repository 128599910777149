.slug:global(.st_input--disabled) {
  opacity: 1;
  pointer-events: auto;
}

.slug:global(.st_input--disabled) input {
  opacity: 0.5;
  pointer-events: none;
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9FZGl0SW1hZ2UvUGFuZWxzL1NsdWcvU2x1Zy5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiXG4uc2x1ZzpnbG9iYWwoLnN0X2lucHV0LS1kaXNhYmxlZCkge1xuICBvcGFjaXR5OiAxO1xuICBwb2ludGVyLWV2ZW50czogYXV0bztcbn1cblxuLnNsdWc6Z2xvYmFsKC5zdF9pbnB1dC0tZGlzYWJsZWQpIGlucHV0IHtcbiAgb3BhY2l0eTogMC41O1xuICBwb2ludGVyLWV2ZW50czogbm9uZTtcbn0iXX0= */