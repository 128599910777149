.image {
  position: relative;
  width: 100%;
}

/* .image:hover .image__btn,
.image__btn:focus {
  opacity: 1;
  z-index: 1;
} */

.toggle {
  appearance: none;
  margin: 0;
  padding: 0;
  display: block;
  outline: none;
  border: none;
  width: 100%;
  background: none;
  cursor: pointer;
}

.toggle:focus-visible {
  background-color: var(--st-btn-gray-bg-active);
}
