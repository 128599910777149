.table {
  border: none;
  border-collapse: collapse;
  color: var(--st-text-color-ink);
}

.table th {
  font-weight: var(--st-font-weight-bold);
  text-align: left;
  vertical-align: top;
}

.table td {
  vertical-align: top;
  padding-left: var(--st-spacer-lg);
  overflow-wrap: anywhere;
}

.table tr {
  width: 100%;
}
.table tr + tr th,
.table tr + tr td {
  padding-top: var(--st-spacer-xs);
}

.box {
  background-color: var(--st-page-bg);
}

.embeddedMetadata {
  background: var(--st-color-blurple-10);
  border-color: var(--st-color-blurple-70);
  color: var(--st-color-blurple-70);
  border-bottom: var(--st-border-width) solid;
  border-top: var(--st-border-width) solid;
  padding-bottom: var(--st-spacer-md);
}
