.colorPicker__resize__padding_color {
  border: var(--st-border-width) solid var(--st-border-color);
  cursor: pointer;
}

.colorPicker__resize__padding_color__transparent_bg {
  background-image: linear-gradient(-45deg, transparent 75%, #bfbfbf 75%), linear-gradient(45deg, transparent 75%, #bfbfbf 75%), linear-gradient(-45deg, #bfbfbf 25%, transparent 25%), linear-gradient(45deg, #bfbfbf 25%, transparent 25%);
  background-size: 6px 6px;
  background-position: 0 0, 0 9px, 9px -9px, -9px 0px;
}

.colorPicker__resize__padding_color__color_box {
  width: 60px;
  height: 29px;
}