.image {
  position: relative;
  width: 100%;
}

/* .image:hover .image__btn,
.image__btn:focus {
  opacity: 1;
  z-index: 1;
} */
.toggle {
  appearance: none;
  margin: 0;
  padding: 0;
  display: block;
  outline: none;
  border: none;
  width: 100%;
  background: none;
  cursor: pointer;
}

.toggle:focus-visible {
  background-color: var(--st-btn-gray-bg-active);
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9MaWdodGJveC9MaWdodGJveC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTs7O0FBR0Y7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQU1BO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLmltYWdlIHtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICB3aWR0aDogMTAwJTtcbn1cblxuLyogLmltYWdlOmhvdmVyIC5pbWFnZV9fYnRuLFxuLmltYWdlX19idG46Zm9jdXMge1xuICBvcGFjaXR5OiAxO1xuICB6LWluZGV4OiAxO1xufSAqL1xuXG4udG9nZ2xlIHtcbiAgYXBwZWFyYW5jZTogbm9uZTtcbiAgbWFyZ2luOiAwO1xuICBwYWRkaW5nOiAwO1xuICBkaXNwbGF5OiBibG9jaztcbiAgb3V0bGluZTogbm9uZTtcbiAgYm9yZGVyOiBub25lO1xuICB3aWR0aDogMTAwJTtcbiAgYmFja2dyb3VuZDogbm9uZTtcbiAgY3Vyc29yOiBwb2ludGVyO1xufVxuXG4udG9nZ2xlOmZvY3VzLXZpc2libGUge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1zdC1idG4tZ3JheS1iZy1hY3RpdmUpO1xufVxuIl19 */