/*
add component-specific CSS here
the CSS classes will be transformed by CSS modules when they appear in browser

Use Screentone tokens to setting sizes, colors, fonts
*/
.container {
  box-sizing: border-box;
  min-width: 15rem;
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  font-weight: var(--st-font-weight-med);
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  background: var(--st-input-bg);
  margin: var(--st-spacer-sm);
}

/* .input {
  border: var(--st-border-width) solid var(--st-border-color);
  appearance: none;
  width: 100%;
  height: var(--st-btn-height);
  border-radius: var(--st-border-radius);
  color: var(--st-text-color);
  padding: var(--st-spacer-sm);
  line-height: var(--st-font-line-height-md);
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  box-shadow: none;
  box-sizing: border-box;
  background: transparent;
  /* adjust for icon *-/
  padding-left: calc(var(--st-spacer-mlg) + var(--st-spacer-xs));
  margin-left: calc(-1 * var(--st-spacer-mlg));
}

.input:focus {
  border-color: var(--st-input-color-active);
  outline: none;
  box-shadow: 0 0 0 2px var(--st-color-blurple-opac-50);
}

.inputIcon {
  flex-grow: 0;
  flex-shrink: 0;
  order: -1;
  color: var(--st-input-icon-color);
  height: var(--st-btn-height);
  margin-left: var(--st-spacer-sm);
} */

.options {
  max-height: 26rem;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  margin: var(--st-spacer-sm) 0;
}

.errorContainer {
  display: grid;
  grid-template-columns: 1fr 4fr;
  padding: var(--st-spacer-sm);
}

.errorContainer * {
  color: var(--st-text-color-support);
}

.search__caret__icon {
  margin-left: var(--st-spacer-xs);
}

.user__filter__options {
  max-height: 26rem;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  margin: var(--st-spacer-sm) 0;
}

.user__filter__options div > button {
  height: auto;
  text-transform: capitalize;
}

.user__filter__options__header, 
.user__filter__options__body {
  padding: 0 var(--st-spacer-sm);
}
