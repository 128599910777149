.table {
  border: none;
  border-collapse: collapse;
  color: var(--st-text-color-ink);
}

.table th {
  font-weight: var(--st-font-weight-bold);
  text-align: left;
  vertical-align: top;
}

.table td {
  vertical-align: top;
  padding-left: var(--st-spacer-lg);
  overflow-wrap: anywhere;
}

.table tr {
  width: 100%;
}

.table tr + tr th,
.table tr + tr td {
  padding-top: var(--st-spacer-xs);
}

.box {
  background-color: var(--st-page-bg);
}

.embeddedMetadata {
  background: var(--st-color-blurple-10);
  border-color: var(--st-color-blurple-70);
  color: var(--st-color-blurple-70);
  border-bottom: var(--st-border-width) solid;
  border-top: var(--st-border-width) solid;
  padding-bottom: var(--st-spacer-md);
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9NZXRhZGF0YS9NZXRhZGF0YS5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUVGO0FBQUE7RUFFRTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIudGFibGUge1xuICBib3JkZXI6IG5vbmU7XG4gIGJvcmRlci1jb2xsYXBzZTogY29sbGFwc2U7XG4gIGNvbG9yOiB2YXIoLS1zdC10ZXh0LWNvbG9yLWluayk7XG59XG5cbi50YWJsZSB0aCB7XG4gIGZvbnQtd2VpZ2h0OiB2YXIoLS1zdC1mb250LXdlaWdodC1ib2xkKTtcbiAgdGV4dC1hbGlnbjogbGVmdDtcbiAgdmVydGljYWwtYWxpZ246IHRvcDtcbn1cblxuLnRhYmxlIHRkIHtcbiAgdmVydGljYWwtYWxpZ246IHRvcDtcbiAgcGFkZGluZy1sZWZ0OiB2YXIoLS1zdC1zcGFjZXItbGcpO1xuICBvdmVyZmxvdy13cmFwOiBhbnl3aGVyZTtcbn1cblxuLnRhYmxlIHRyIHtcbiAgd2lkdGg6IDEwMCU7XG59XG4udGFibGUgdHIgKyB0ciB0aCxcbi50YWJsZSB0ciArIHRyIHRkIHtcbiAgcGFkZGluZy10b3A6IHZhcigtLXN0LXNwYWNlci14cyk7XG59XG5cbi5ib3gge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1zdC1wYWdlLWJnKTtcbn1cblxuLmVtYmVkZGVkTWV0YWRhdGEge1xuICBiYWNrZ3JvdW5kOiB2YXIoLS1zdC1jb2xvci1ibHVycGxlLTEwKTtcbiAgYm9yZGVyLWNvbG9yOiB2YXIoLS1zdC1jb2xvci1ibHVycGxlLTcwKTtcbiAgY29sb3I6IHZhcigtLXN0LWNvbG9yLWJsdXJwbGUtNzApO1xuICBib3JkZXItYm90dG9tOiB2YXIoLS1zdC1ib3JkZXItd2lkdGgpIHNvbGlkO1xuICBib3JkZXItdG9wOiB2YXIoLS1zdC1ib3JkZXItd2lkdGgpIHNvbGlkO1xuICBwYWRkaW5nLWJvdHRvbTogdmFyKC0tc3Qtc3BhY2VyLW1kKTtcbn1cbiJdfQ== */