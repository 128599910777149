:global(.st_modal) .iframe {
  height: calc(100% - 62px);
  width: 100%;
  border: 0;
}

.iframe_modal {
  margin: var(--st-spacer-xl);
  border: var(--st-spacer-xs) solid var(--st-border-color);
  border-radius: var(--st-border-radius);
}

:global(.st_modal) .iframe_modal_st_modal__title {
  padding: var(--st-spacer-md);
}

:global(.st_modal) .iframe_modal_st_modal__subtitle {
  padding: var(--st-spacer-sm) var(--st-spacer-md);
  border-top: var(--st-border-width) solid var(--st-border-color);
  border-bottom: var(--st-border-width) solid var(--st-border-color);
}

.image_ref {
  max-width: 100%;
}

.image_ref_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(var(--st-spacer-smd) - var(--st-spacer-xs)) calc(var(--st-spacer-mlg) - var(--st-spacer-xs)) calc(var(--st-spacer-smd) - var(--st-spacer-xs)) calc(var(--st-spacer-mlg));
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvcm91dGVzL2RldmVsb3Blci9pZnJhbWUvaWZyYW1lLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTs7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIjpnbG9iYWwoLnN0X21vZGFsKSAuaWZyYW1lIHtcbiAgaGVpZ2h0OiBjYWxjKDEwMCUgLSA2MnB4KTtcbiAgd2lkdGg6IDEwMCU7XG4gIGJvcmRlcjogMDtcbn1cblxuLmlmcmFtZV9tb2RhbCB7XG4gIG1hcmdpbjogdmFyKC0tc3Qtc3BhY2VyLXhsKTtcbiAgYm9yZGVyOiB2YXIoLS1zdC1zcGFjZXIteHMpIHNvbGlkIHZhcigtLXN0LWJvcmRlci1jb2xvcik7XG4gIGJvcmRlci1yYWRpdXM6IHZhcigtLXN0LWJvcmRlci1yYWRpdXMpO1xufVxuXG46Z2xvYmFsKC5zdF9tb2RhbCkgLmlmcmFtZV9tb2RhbF9zdF9tb2RhbF9fdGl0bGUge1xuICBwYWRkaW5nOiB2YXIoLS1zdC1zcGFjZXItbWQpO1xufVxuXG46Z2xvYmFsKC5zdF9tb2RhbCkgLmlmcmFtZV9tb2RhbF9zdF9tb2RhbF9fc3VidGl0bGUge1xuICBwYWRkaW5nOiB2YXIoLS1zdC1zcGFjZXItc20pIHZhcigtLXN0LXNwYWNlci1tZCk7XG4gIGJvcmRlci10b3A6IHZhcigtLXN0LWJvcmRlci13aWR0aCkgc29saWQgdmFyKC0tc3QtYm9yZGVyLWNvbG9yKTtcbiAgYm9yZGVyLWJvdHRvbTogdmFyKC0tc3QtYm9yZGVyLXdpZHRoKSBzb2xpZCB2YXIoLS1zdC1ib3JkZXItY29sb3IpO1xufVxuXG4uaW1hZ2VfcmVmIHtcbiAgbWF4LXdpZHRoOiAxMDAlO1xufVxuLmltYWdlX3JlZl9ib3gge1xuICBkaXNwbGF5OiBmbGV4O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIHBhZGRpbmc6IGNhbGModmFyKC0tc3Qtc3BhY2VyLXNtZCkgLSB2YXIoLS1zdC1zcGFjZXIteHMpKSBjYWxjKHZhcigtLXN0LXNwYWNlci1tbGcpIC0gdmFyKC0tc3Qtc3BhY2VyLXhzKSlcbiAgICBjYWxjKHZhcigtLXN0LXNwYWNlci1zbWQpIC0gdmFyKC0tc3Qtc3BhY2VyLXhzKSkgY2FsYyh2YXIoLS1zdC1zcGFjZXItbWxnKSk7XG59Il19 */