.placements__container {
  padding: var(--st-spacer-sm) !important;
  width: calc(var(--st-spacer-lg) * 3 - var(--st-spacer-sm));
  background-color: var(--st-page-bg);
  border: var(--st-border-width) solid var(--st-border-color);
}

.placements__container button {
  padding: 0;
  width: var(--st-spacer-mlg);
  height: var(--st-spacer-mlg);
  background-color: var(--st-content-bg);
  border: var(--st-border-width) solid var(--st-border-color);
}

.placements__container button.placements__container_active {
  background-color: var(--st-color-blurple-10);
  border: var(--st-border-width) solid var(--st-color-blurple-70);
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9FZGl0SW1hZ2UvUGFuZWxzL092ZXJsYXlzL092ZXJsYXlzLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJcblxuLnBsYWNlbWVudHNfX2NvbnRhaW5lciB7XG4gIHBhZGRpbmc6IHZhcigtLXN0LXNwYWNlci1zbSkgIWltcG9ydGFudDtcbiAgd2lkdGg6IGNhbGMoKHZhcigtLXN0LXNwYWNlci1sZykgKiAzKSAtIHZhcigtLXN0LXNwYWNlci1zbSkpO1xuICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1zdC1wYWdlLWJnKTtcbiAgYm9yZGVyOiB2YXIoLS1zdC1ib3JkZXItd2lkdGgpIHNvbGlkIHZhcigtLXN0LWJvcmRlci1jb2xvcik7XG59XG5cbi5wbGFjZW1lbnRzX19jb250YWluZXIgYnV0dG9uIHtcbiAgcGFkZGluZzogMDtcbiAgd2lkdGg6IHZhcigtLXN0LXNwYWNlci1tbGcpOyAgXG4gIGhlaWdodDogdmFyKC0tc3Qtc3BhY2VyLW1sZyk7XG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXN0LWNvbnRlbnQtYmcpO1xuICBib3JkZXI6IHZhcigtLXN0LWJvcmRlci13aWR0aCkgc29saWQgdmFyKC0tc3QtYm9yZGVyLWNvbG9yKTtcbn1cblxuLnBsYWNlbWVudHNfX2NvbnRhaW5lciBidXR0b24ucGxhY2VtZW50c19fY29udGFpbmVyX2FjdGl2ZSB7XG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXN0LWNvbG9yLWJsdXJwbGUtMTApO1xuICBib3JkZXI6IHZhcigtLXN0LWJvcmRlci13aWR0aCkgc29saWQgdmFyKC0tc3QtY29sb3ItYmx1cnBsZS03MCk7XG59XG4iXX0= */