
.override__st_btn--ibm {
  appearance: none;
  outline: 0;
  border: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--st-btn-secondary-color);
  background: var(--st-btn-secondary-bg);
  justify-content: center;
  box-shadow: 0 2px var(--st-spacer-md) var(--st-input-outline-color-active);
  transition: all .4s;
}

.override__st_btn--ibm:hover {
  background: var(--st-btn-secondary-bg-hover);
  color: var(--st-btn-primary-bg);
  transform: scale(1.1);
}

.st_overlay__content--ibm_wrapper {
  width: 100%;
  max-width: calc( 88 + var(--st-grid-page-fullwidth));
  height: 90%;
}

.st_overlay__content--ibm_wrapper_group {
  height: 100%;
  margin-left: var(--st-spacer-mlg); 
  margin-right: var(--st-spacer-mlg);
}

.st_overlay__content--ibm_content {
  flex-grow: 1;
  background-color: var(--st-page-bg);
  margin-left: var(--st-spacer-xl);
  height: 100%;
  border: none;
  overflow-y: auto;
}