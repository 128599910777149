.container {
  background-color: var(--st-content-bg);
  width: fit-content;
}

.presets {
  align-items: center;
  border-bottom: var(--st-border-width) solid var(--st-color-stainless);
  display: flex;
  justify-content: space-between;
  padding: var(--st-spacer-md);
}

.textContent {
  border-bottom: var(--st-border-width) solid var(--st-color-stainless);
  padding: var(--st-spacer-md);
}

.buttons {
  border-top: var(--st-border-width) solid var(--st-color-stainless);
  display: flex;
  justify-content: right;
  padding: var(--st-spacer-md);
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9TZWFyY2hiYXIvRGF0ZUZpbHRlci9EYXRlRmlsdGVyLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5jb250YWluZXIge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1zdC1jb250ZW50LWJnKTtcbiAgd2lkdGg6IGZpdC1jb250ZW50O1xufVxuXG4ucHJlc2V0cyB7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGJvcmRlci1ib3R0b206IHZhcigtLXN0LWJvcmRlci13aWR0aCkgc29saWQgdmFyKC0tc3QtY29sb3Itc3RhaW5sZXNzKTtcbiAgZGlzcGxheTogZmxleDtcbiAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuICBwYWRkaW5nOiB2YXIoLS1zdC1zcGFjZXItbWQpO1xufVxuXG4udGV4dENvbnRlbnQge1xuICBib3JkZXItYm90dG9tOiB2YXIoLS1zdC1ib3JkZXItd2lkdGgpIHNvbGlkIHZhcigtLXN0LWNvbG9yLXN0YWlubGVzcyk7XG4gIHBhZGRpbmc6IHZhcigtLXN0LXNwYWNlci1tZCk7XG59XG5cbi5idXR0b25zIHtcbiAgYm9yZGVyLXRvcDogdmFyKC0tc3QtYm9yZGVyLXdpZHRoKSBzb2xpZCB2YXIoLS1zdC1jb2xvci1zdGFpbmxlc3MpO1xuICBkaXNwbGF5OiBmbGV4O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IHJpZ2h0O1xuICBwYWRkaW5nOiB2YXIoLS1zdC1zcGFjZXItbWQpO1xufVxuIl19 */