.action_button svg {
  animation: spin .8s linear infinite;
  animation-name: spin;
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}