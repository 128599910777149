.metadata {
  margin: 0;
}

.metadata dd,
.metadata dt {
  margin: 0;
  padding: 0;
  display: inline;
}
