
.opacity {
  opacity: 0.5;
}

.preview__menu {
  width: 100%;
}

.preview__menuitem {
  list-style: none;
  padding: 0;
  margin: 0;
}

.preview__button {
  appearance: none;
  border: none;
  cursor: pointer;
  background: none;
  display: flex;
  flex-direction: column;
  color: var(--st-text-color);
  border-radius: var(--st-border-radius);
  padding: 0;
}

.preview__button span {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  width: var(--im-preview-width);
}

.preview__button:disabled {
  cursor: default;
}

.preview__button:focus {
  outline: none;
}

.preview__button:focus::before {
  outline: 2px solid var(--st-input-outline-color-active);
  outline-offset: -2px;
}

.preview__button[aria-current='true']::before {
  outline: 2px solid var(--st-input-color-active);
  outline-offset: -2px;
  border-radius: var(--st-border-radius);
}

.preview__button::before {
  width: auto;
  height: 100px;
  background-color: var(--st-border-color);
  background-image: var(--im-preview-url);
  background-size: contain;
  aspect-ratio: var(--im-preview-ratio);
  display: block;
  content: '';
  margin-bottom: var(--st-spacer-xs);
  border-radius: var(--st-border-radius);
  background-repeat: no-repeat;
  background-position: center;
}

.preview__button.preview__button--tall::before {
  width: auto;
  height: 100px;
  min-width: 5px;
}

.preview__button.preview__button--small::before {
  min-height: 75px;
}