

.placements__container {
  padding: var(--st-spacer-sm) !important;
  width: calc((var(--st-spacer-lg) * 3) - var(--st-spacer-sm));
  background-color: var(--st-page-bg);
  border: var(--st-border-width) solid var(--st-border-color);
}

.placements__container button {
  padding: 0;
  width: var(--st-spacer-mlg);  
  height: var(--st-spacer-mlg);
  background-color: var(--st-content-bg);
  border: var(--st-border-width) solid var(--st-border-color);
}

.placements__container button.placements__container_active {
  background-color: var(--st-color-blurple-10);
  border: var(--st-border-width) solid var(--st-color-blurple-70);
}
