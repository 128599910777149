.container {
  background-color: var(--st-content-bg);
  width: fit-content;
}

.presets {
  align-items: center;
  border-bottom: var(--st-border-width) solid var(--st-color-stainless);
  display: flex;
  justify-content: space-between;
  padding: var(--st-spacer-md);
}

.textContent {
  border-bottom: var(--st-border-width) solid var(--st-color-stainless);
  padding: var(--st-spacer-md);
}

.buttons {
  border-top: var(--st-border-width) solid var(--st-color-stainless);
  display: flex;
  justify-content: right;
  padding: var(--st-spacer-md);
}
