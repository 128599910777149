
.image {
  background-color: var(--st-color-char);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 530px; /* hard-coded so page doesn't jump */
  position: relative;
  border-color: var(--st-border-color);
  border-style: solid;
  border-width: 0 1px 1px 1px;
}

.image .focal_image {
  max-width: 100%;
  max-height: 530px;
}
