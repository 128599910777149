.errorWrapper {
  position: relative;
  z-index: var(--st-zindex-minor);
}

.errorContainer {
  position: absolute;
  width: 100%;
}

.uploadWrapper {
  min-height: calc(100vh - var(--st-header-height));
}

.uploadWrapperWithoutHeader {
  min-height: 100vh;
  display: grid;
  align-content: space-around;
}
.uploadWrapperWithoutHeader > div {
  padding: 0 0 0 0;
}

.uploadWrapperContent {
  padding-top: calc(var(--st-header-height) + var(--st-spacer-mlg));
  padding-bottom: var(--st-spacer-mlg);
}

.onDragOver {
  background-color: var(--st-btn-secondary-bg-hover);
}

.upload__panel_box {
  background: var(--st-content-bg);
  border: var(--st-border-width) var(--st-border-color) solid;
  text-align: center;
  padding: var(--st-spacer-lg);
  border-radius: var(--st-border-radius);
}

.onDragOver .upload__panel_box {
  background-color: var(--st-btn-secondary-bg-active);
}

.upload__panel_box_img {
  margin: 0 auto;
  width: 300px;
  max-width: 100%;
}

.pageWrapperContent {
  padding-top: calc(var(--st-header-height) + var(--st-spacer-smd));
  padding-bottom: var(--st-spacer-mlg);
}
