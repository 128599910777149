
.input_file::file-selector-button {
  height: var(--st-spacer-mlg);
  padding: 0 var(--st-spacer-sm);
  background: var(--st-btn-secondary-bg);
  border: 1px solid var(--st-btn-secondary-color);
  color: var(--st-btn-secondary-color);
  justify-content: center;
  display: inline-flex;
  align-items: center;
  box-shadow: none;
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
  font-weight: var(--st-font-weight-med);
  border-radius: var(--st-border-radius);
  cursor: pointer;
  text-decoration: none;
  transition: all 400ms;
  box-sizing: border-box;
  white-space: nowrap;
}
