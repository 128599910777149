:global(.nocss) {
  display: none;
}

:global(.st_alert--floating.alert_message) {
  width: 600px;
  margin-top: var(--st-spacer-xl);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--st-spacer-sm);
}

.im_browser_mode__iframe :global(.st_headerstickywrapper) header {
  display: none;
}

.im_browser_mode__standalone {
  min-height: 100vh;
  /* margin-bottom: var(--st-spacer-sm); */
}


:global(.st_label .st_text--sm .st_text--md.st_text--color-lava) {
  line-height: var(--st-font-line-height-sm);
}
:global(.st_sidebar__page.st_sidebar__page--right.st_sidebar__page--open .st_btn--fab-container) {
  right: var(--st-sidebar-width-open);
}

:global(.st_sidebar__page.st_sidebar__page--right.st_sidebar__page--collapsed .st_btn--fab-container) {
  right: var(--st-sidebar-width-collapsed);
}

:global(.st_sidebar__page .st_btn--fab-container .st_btn--fab-inner) {
  margin-right: var(--st-fab-right, var(--st-spacer-lg));
}
