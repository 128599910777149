.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  column-gap: var(--st-spacer-md);
  align-items: stretch;
}

.item {
  display: block;
  cursor: pointer;
  position: relative;
  background-color: var(--st-border-color);
  border-radius: var(--st-border-radius);
}

.item:focus-within {
  background-color: var(--st-color-blurple-opac-50);
  outline: 4px solid var(--st-color-blurple-opac-50);
}
.item:focus-within:has(.item__download_icon:focus) {
  outline: 0;
}

.item__meta {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* top: var(--st-spacer-sm); */
  /* left: var(--st-spacer-sm); */
}

.item__a_img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.item.item--selected .item__a_img {
  opacity: 0.5;
}

.item__img {
  background-image: var(--im-preview-url);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.item.item--active {
  background-color: var(--st-color-blurple-50);
  outline: 4px solid var(--st-color-blurple-50);
}

.gallery {
  display: flex;
  flex-direction: column;
  row-gap: var(--st-spacer-md);
  width: calc(100% - var(--st-spacer-xl));
  max-width: var(--st-grid-page-fullwidth);
  margin: 0 auto var(--st-spacer-xl) auto;
  transition: 0.3s width; /* to interact with the Lightbox animation */
}

@media screen and (min-width: 1440px) {
  .gallery:not(.gallery--fullwidth) {
    max-width: unset;
    width: calc(100% - 3.5 * (var(--st-spacer-xl) + var(--st-spacer-sm)));
  }

  .gallery.gallery--fullwidth {
    max-width: unset;
  }
}

.loading {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item:hover .item__select,
.item:focus-within .item__select {
  opacity: 1;
  z-index: 1;
}

.item__select {
  position: absolute;
  bottom: var(--st-spacer-sm);
  right: var(--st-spacer-sm);
  opacity: 0;
  z-index: -1;
}

.item:hover :global(.st_tooltip_trigger .st_btn.st_btn--tertiary) {
  background: var(--st-color-white);
}

.item :global(.st_btn.st_btn--tertiary).item__download_icon {
  opacity: 0;
}

.item:hover :global(.st_btn.st_btn--tertiary).item__download_icon {
  opacity: 1;
  background-color: rgb(0 0 0 / 50%);
}

.item:hover :global(.st_btn.st_btn--tertiary).item__download_icon:hover {
  background-color: rgb(255 255 255 / 50%);
  color: var(--st-btn-gray-color);
}