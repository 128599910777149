:global(.st_row).row_nowrap {
  flex-wrap: nowrap;
}

:global(.st_row).row_wrap {
  flex-wrap: wrap;
}

.section {
  margin-right: var(--st-spacer-mlg);
}

.search__caret__icon {
  margin-left: var(--st-spacer-xs);
}

input.filter_cb_exclude:checked:after {
  /* content: "✗"; */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill ='%23fff' d='M8.943 8l3.529 3.529c0.26 0.26 0.26 0.682 0 0.943s-0.682 0.26-0.943 0l-3.529-3.529-3.529 3.529c-0.26 0.26-0.682 0.26-0.943 0s-0.26-0.682 0-0.943l3.529-3.529-3.529-3.529c-0.26-0.26-0.26-0.682 0-0.943s0.682-0.26 0.943 0l3.529 3.529 3.529-3.529c0.26-0.26 0.682-0.26 0.943 0s0.26 0.682 0 0.943z' /%3E%3C/svg%3E");
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9TZWFyY2hiYXIvQWR2YW5jZWRTZWFyY2gvQWR2YW5jZWRTZWFyY2gubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFOzs7QUFHRjtBQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyI6Z2xvYmFsKC5zdF9yb3cpLnJvd19ub3dyYXAge1xuICBmbGV4LXdyYXA6IG5vd3JhcDtcbn1cblxuOmdsb2JhbCguc3Rfcm93KS5yb3dfd3JhcCB7XG4gIGZsZXgtd3JhcDogd3JhcDtcbn1cblxuLnNlY3Rpb24ge1xuICBtYXJnaW4tcmlnaHQ6IHZhcigtLXN0LXNwYWNlci1tbGcpO1xufVxuXG4uc2VhcmNoX19jYXJldF9faWNvbiB7XG4gIG1hcmdpbi1sZWZ0OiB2YXIoLS1zdC1zcGFjZXIteHMpO1xufVxuXG5pbnB1dC5maWx0ZXJfY2JfZXhjbHVkZTpjaGVja2VkOmFmdGVyIHtcbiAgLyogY29udGVudDogXCLinJdcIjsgKi9cbiAgYmFja2dyb3VuZC1pbWFnZTogdXJsKFwiZGF0YTppbWFnZS9zdmcreG1sLCUzQ3N2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNiclM0UlM0NwYXRoIGZpbGwgPSclMjNmZmYnIGQ9J004Ljk0MyA4bDMuNTI5IDMuNTI5YzAuMjYgMC4yNiAwLjI2IDAuNjgyIDAgMC45NDNzLTAuNjgyIDAuMjYtMC45NDMgMGwtMy41MjktMy41MjktMy41MjkgMy41MjljLTAuMjYgMC4yNi0wLjY4MiAwLjI2LTAuOTQzIDBzLTAuMjYtMC42ODIgMC0wLjk0M2wzLjUyOS0zLjUyOS0zLjUyOS0zLjUyOWMtMC4yNi0wLjI2LTAuMjYtMC42ODIgMC0wLjk0M3MwLjY4Mi0wLjI2IDAuOTQzIDBsMy41MjkgMy41MjkgMy41MjktMy41MjljMC4yNi0wLjI2IDAuNjgyLTAuMjYgMC45NDMgMHMwLjI2IDAuNjgyIDAgMC45NDN6JyAvJTNFJTNDL3N2ZyUzRVwiKTtcbn1cbiJdfQ== */