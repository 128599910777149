.borderTitleBottom {
  border-bottom: var(--st-border-width) solid var(--st-border-color);
}

.pixelLimitWarning {
  color: var(--st-color-sunshine-70);
  background-color: var(--st-color-sunshine-10);
  border: 0px;
  border-bottom: var(--st-border-width) solid var(--st-color-sunshine-70);
  border-radius: 0;
}

.pixelLimitError {
  color: var(--st-color-lava-70);
  background-color: var(--st-color-lava-10);
  border: 0px;
  border-bottom: var(--st-border-width) solid var(--st-color-lava-70);
  border-radius: 0;
}

.colorPicker :global(.st_pallete-container) {
  background-color: #fff;
  margin-bottom: 1rem;
}

.colorPicker :global(.st_color-picker--sample) {
  border: var(--st-border-width) solid var(--st-border-color);
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9FZGl0TWV0YWRhdGFDYXJkL0VkaXRNZXRhZGF0YUNhcmQubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBOzs7QUFHRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLmJvcmRlclRpdGxlQm90dG9tIHtcbiAgYm9yZGVyLWJvdHRvbTogdmFyKC0tc3QtYm9yZGVyLXdpZHRoKSBzb2xpZCB2YXIoLS1zdC1ib3JkZXItY29sb3IpO1xufVxuXG4ucGl4ZWxMaW1pdFdhcm5pbmcge1xuICBjb2xvcjogdmFyKC0tc3QtY29sb3Itc3Vuc2hpbmUtNzApO1xuICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1zdC1jb2xvci1zdW5zaGluZS0xMCk7XG4gIGJvcmRlcjogMHB4O1xuICBib3JkZXItYm90dG9tOiB2YXIoLS1zdC1ib3JkZXItd2lkdGgpIHNvbGlkIHZhcigtLXN0LWNvbG9yLXN1bnNoaW5lLTcwKTtcbiAgYm9yZGVyLXJhZGl1czogMDtcbn1cblxuLnBpeGVsTGltaXRFcnJvciB7XG4gIGNvbG9yOiB2YXIoLS1zdC1jb2xvci1sYXZhLTcwKTtcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tc3QtY29sb3ItbGF2YS0xMCk7XG4gIGJvcmRlcjogMHB4O1xuICBib3JkZXItYm90dG9tOiB2YXIoLS1zdC1ib3JkZXItd2lkdGgpIHNvbGlkIHZhcigtLXN0LWNvbG9yLWxhdmEtNzApO1xuICBib3JkZXItcmFkaXVzOiAwO1xufVxuXG4uY29sb3JQaWNrZXIgOmdsb2JhbCguc3RfcGFsbGV0ZS1jb250YWluZXIpIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2ZmZjtcbiAgbWFyZ2luLWJvdHRvbTogMXJlbTtcbn1cblxuLmNvbG9yUGlja2VyIDpnbG9iYWwoLnN0X2NvbG9yLXBpY2tlci0tc2FtcGxlKSB7XG4gIGJvcmRlcjogdmFyKC0tc3QtYm9yZGVyLXdpZHRoKSBzb2xpZCB2YXIoLS1zdC1ib3JkZXItY29sb3IpO1xufVxuIl19 */