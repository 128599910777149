:global(.nocss) {
  display: none;
}

:global(.st_alert--floating.alert_message) {
  width: 600px;
  margin-top: var(--st-spacer-xl);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--st-spacer-sm);
}

.im_browser_mode__iframe :global(.st_headerstickywrapper) header {
  display: none;
}

.im_browser_mode__standalone {
  min-height: 100vh;
  /* margin-bottom: var(--st-spacer-sm); */
}

:global(.st_label .st_text--sm .st_text--md.st_text--color-lava) {
  line-height: var(--st-font-line-height-sm);
}

:global(.st_sidebar__page.st_sidebar__page--right.st_sidebar__page--open .st_btn--fab-container) {
  right: var(--st-sidebar-width-open);
}

:global(.st_sidebar__page.st_sidebar__page--right.st_sidebar__page--collapsed .st_btn--fab-container) {
  right: var(--st-sidebar-width-collapsed);
}

:global(.st_sidebar__page .st_btn--fab-container .st_btn--fab-inner) {
  margin-right: var(--st-fab-right, var(--st-spacer-lg));
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvQXBwLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFO0FBQ0E7OztBQUlGO0VBQ0U7OztBQUVGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyI6Z2xvYmFsKC5ub2Nzcykge1xuICBkaXNwbGF5OiBub25lO1xufVxuXG46Z2xvYmFsKC5zdF9hbGVydC0tZmxvYXRpbmcuYWxlcnRfbWVzc2FnZSkge1xuICB3aWR0aDogNjAwcHg7XG4gIG1hcmdpbi10b3A6IHZhcigtLXN0LXNwYWNlci14bCk7XG4gIG1hcmdpbi1sZWZ0OiBhdXRvO1xuICBtYXJnaW4tcmlnaHQ6IGF1dG87XG4gIG1hcmdpbi1ib3R0b206IHZhcigtLXN0LXNwYWNlci1zbSk7XG59XG5cbi5pbV9icm93c2VyX21vZGVfX2lmcmFtZSA6Z2xvYmFsKC5zdF9oZWFkZXJzdGlja3l3cmFwcGVyKSBoZWFkZXIge1xuICBkaXNwbGF5OiBub25lO1xufVxuXG4uaW1fYnJvd3Nlcl9tb2RlX19zdGFuZGFsb25lIHtcbiAgbWluLWhlaWdodDogMTAwdmg7XG4gIC8qIG1hcmdpbi1ib3R0b206IHZhcigtLXN0LXNwYWNlci1zbSk7ICovXG59XG5cblxuOmdsb2JhbCguc3RfbGFiZWwgLnN0X3RleHQtLXNtIC5zdF90ZXh0LS1tZC5zdF90ZXh0LS1jb2xvci1sYXZhKSB7XG4gIGxpbmUtaGVpZ2h0OiB2YXIoLS1zdC1mb250LWxpbmUtaGVpZ2h0LXNtKTtcbn1cbjpnbG9iYWwoLnN0X3NpZGViYXJfX3BhZ2Uuc3Rfc2lkZWJhcl9fcGFnZS0tcmlnaHQuc3Rfc2lkZWJhcl9fcGFnZS0tb3BlbiAuc3RfYnRuLS1mYWItY29udGFpbmVyKSB7XG4gIHJpZ2h0OiB2YXIoLS1zdC1zaWRlYmFyLXdpZHRoLW9wZW4pO1xufVxuXG46Z2xvYmFsKC5zdF9zaWRlYmFyX19wYWdlLnN0X3NpZGViYXJfX3BhZ2UtLXJpZ2h0LnN0X3NpZGViYXJfX3BhZ2UtLWNvbGxhcHNlZCAuc3RfYnRuLS1mYWItY29udGFpbmVyKSB7XG4gIHJpZ2h0OiB2YXIoLS1zdC1zaWRlYmFyLXdpZHRoLWNvbGxhcHNlZCk7XG59XG5cbjpnbG9iYWwoLnN0X3NpZGViYXJfX3BhZ2UgLnN0X2J0bi0tZmFiLWNvbnRhaW5lciAuc3RfYnRuLS1mYWItaW5uZXIpIHtcbiAgbWFyZ2luLXJpZ2h0OiB2YXIoLS1zdC1mYWItcmlnaHQsIHZhcigtLXN0LXNwYWNlci1sZykpO1xufVxuIl19 */