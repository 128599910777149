.borderTitleBottom {
  border-bottom: var(--st-border-width) solid var(--st-border-color);
}

.pixelLimitWarning {
  color: var(--st-color-sunshine-70);
  background-color: var(--st-color-sunshine-10);
  border: 0px;
  border-bottom: var(--st-border-width) solid var(--st-color-sunshine-70);
  border-radius: 0;
}

.pixelLimitError {
  color: var(--st-color-lava-70);
  background-color: var(--st-color-lava-10);
  border: 0px;
  border-bottom: var(--st-border-width) solid var(--st-color-lava-70);
  border-radius: 0;
}

.colorPicker :global(.st_pallete-container) {
  background-color: #fff;
  margin-bottom: 1rem;
}

.colorPicker :global(.st_color-picker--sample) {
  border: var(--st-border-width) solid var(--st-border-color);
}
