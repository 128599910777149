.override__st_btn--ibm {
  appearance: none;
  outline: 0;
  border: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--st-btn-secondary-color);
  background: var(--st-btn-secondary-bg);
  justify-content: center;
  box-shadow: 0 2px var(--st-spacer-md) var(--st-input-outline-color-active);
  transition: all 0.4s;
}

.override__st_btn--ibm:hover {
  background: var(--st-btn-secondary-bg-hover);
  color: var(--st-btn-primary-bg);
  transform: scale(1.1);
}

.st_overlay__content--ibm_wrapper {
  width: 100%;
  max-width: calc(88 + var(--st-grid-page-fullwidth));
  height: 90%;
}

.st_overlay__content--ibm_wrapper_group {
  height: 100%;
  margin-left: var(--st-spacer-mlg);
  margin-right: var(--st-spacer-mlg);
}

.st_overlay__content--ibm_content {
  flex-grow: 1;
  background-color: var(--st-page-bg);
  margin-left: var(--st-spacer-xl);
  height: 100%;
  border: none;
  overflow-y: auto;
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9Jbm5lckJyb3dzZXJNb2RlbC9Jbm5lckJyb3dzZXJNb2RlbC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5vdmVycmlkZV9fc3RfYnRuLS1pYm0ge1xuICBhcHBlYXJhbmNlOiBub25lO1xuICBvdXRsaW5lOiAwO1xuICBib3JkZXI6IDA7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGN1cnNvcjogcG9pbnRlcjtcbiAgd2lkdGg6IDQwcHg7XG4gIGhlaWdodDogNDBweDtcbiAgYm9yZGVyLXJhZGl1czogNTAlO1xuICBjb2xvcjogdmFyKC0tc3QtYnRuLXNlY29uZGFyeS1jb2xvcik7XG4gIGJhY2tncm91bmQ6IHZhcigtLXN0LWJ0bi1zZWNvbmRhcnktYmcpO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgYm94LXNoYWRvdzogMCAycHggdmFyKC0tc3Qtc3BhY2VyLW1kKSB2YXIoLS1zdC1pbnB1dC1vdXRsaW5lLWNvbG9yLWFjdGl2ZSk7XG4gIHRyYW5zaXRpb246IGFsbCAuNHM7XG59XG5cbi5vdmVycmlkZV9fc3RfYnRuLS1pYm06aG92ZXIge1xuICBiYWNrZ3JvdW5kOiB2YXIoLS1zdC1idG4tc2Vjb25kYXJ5LWJnLWhvdmVyKTtcbiAgY29sb3I6IHZhcigtLXN0LWJ0bi1wcmltYXJ5LWJnKTtcbiAgdHJhbnNmb3JtOiBzY2FsZSgxLjEpO1xufVxuXG4uc3Rfb3ZlcmxheV9fY29udGVudC0taWJtX3dyYXBwZXIge1xuICB3aWR0aDogMTAwJTtcbiAgbWF4LXdpZHRoOiBjYWxjKCA4OCArIHZhcigtLXN0LWdyaWQtcGFnZS1mdWxsd2lkdGgpKTtcbiAgaGVpZ2h0OiA5MCU7XG59XG5cbi5zdF9vdmVybGF5X19jb250ZW50LS1pYm1fd3JhcHBlcl9ncm91cCB7XG4gIGhlaWdodDogMTAwJTtcbiAgbWFyZ2luLWxlZnQ6IHZhcigtLXN0LXNwYWNlci1tbGcpOyBcbiAgbWFyZ2luLXJpZ2h0OiB2YXIoLS1zdC1zcGFjZXItbWxnKTtcbn1cblxuLnN0X292ZXJsYXlfX2NvbnRlbnQtLWlibV9jb250ZW50IHtcbiAgZmxleC1ncm93OiAxO1xuICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1zdC1wYWdlLWJnKTtcbiAgbWFyZ2luLWxlZnQ6IHZhcigtLXN0LXNwYWNlci14bCk7XG4gIGhlaWdodDogMTAwJTtcbiAgYm9yZGVyOiBub25lO1xuICBvdmVyZmxvdy15OiBhdXRvO1xufSJdfQ== */