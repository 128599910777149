.calendar {
  position: fixed;
}

.autocomplete {
  position: relative; 
  
}
.autocomplete_container{
   width: 100%;
}

