.calendar {
  position: fixed;
}

.autocomplete {
  position: relative;
}

.autocomplete_container {
  width: 100%;
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9FZGl0V2lyZU1ldGFkYXRhU2lkZWJhci9FZGl0V2lyZU1ldGFkYXRhRm9ybS9FZGl0V2lyZXNNZXRhZGF0YUZvcm0ubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNHIiwic291cmNlc0NvbnRlbnQiOlsiLmNhbGVuZGFyIHtcbiAgcG9zaXRpb246IGZpeGVkO1xufVxuXG4uYXV0b2NvbXBsZXRlIHtcbiAgcG9zaXRpb246IHJlbGF0aXZlOyBcbiAgXG59XG4uYXV0b2NvbXBsZXRlX2NvbnRhaW5lcntcbiAgIHdpZHRoOiAxMDAlO1xufVxuXG4iXX0= */