.colorPicker__resize__padding_color {
  border: var(--st-border-width) solid var(--st-border-color);
  cursor: pointer;
}

.colorPicker__resize__padding_color__transparent_bg {
  background-image: linear-gradient(-45deg, transparent 75%, #bfbfbf 75%), linear-gradient(45deg, transparent 75%, #bfbfbf 75%), linear-gradient(-45deg, #bfbfbf 25%, transparent 25%), linear-gradient(45deg, #bfbfbf 25%, transparent 25%);
  background-size: 6px 6px;
  background-position: 0 0, 0 9px, 9px -9px, -9px 0px;
}

.colorPicker__resize__padding_color__color_box {
  width: 60px;
  height: 29px;
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9QYWRkaW5nQ29sb3IvUGFkZGluZ0NvbG9yLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLmNvbG9yUGlja2VyX19yZXNpemVfX3BhZGRpbmdfY29sb3Ige1xuICBib3JkZXI6IHZhcigtLXN0LWJvcmRlci13aWR0aCkgc29saWQgdmFyKC0tc3QtYm9yZGVyLWNvbG9yKTtcbiAgY3Vyc29yOiBwb2ludGVyO1xufVxuXG4uY29sb3JQaWNrZXJfX3Jlc2l6ZV9fcGFkZGluZ19jb2xvcl9fdHJhbnNwYXJlbnRfYmcge1xuICBiYWNrZ3JvdW5kLWltYWdlOiBsaW5lYXItZ3JhZGllbnQoLTQ1ZGVnLCB0cmFuc3BhcmVudCA3NSUsICNiZmJmYmYgNzUlKSwgbGluZWFyLWdyYWRpZW50KDQ1ZGVnLCB0cmFuc3BhcmVudCA3NSUsICNiZmJmYmYgNzUlKSwgbGluZWFyLWdyYWRpZW50KC00NWRlZywgI2JmYmZiZiAyNSUsIHRyYW5zcGFyZW50IDI1JSksIGxpbmVhci1ncmFkaWVudCg0NWRlZywgI2JmYmZiZiAyNSUsIHRyYW5zcGFyZW50IDI1JSk7XG4gIGJhY2tncm91bmQtc2l6ZTogNnB4IDZweDtcbiAgYmFja2dyb3VuZC1wb3NpdGlvbjogMCAwLCAwIDlweCwgOXB4IC05cHgsIC05cHggMHB4O1xufVxuXG4uY29sb3JQaWNrZXJfX3Jlc2l6ZV9fcGFkZGluZ19jb2xvcl9fY29sb3JfYm94IHtcbiAgd2lkdGg6IDYwcHg7XG4gIGhlaWdodDogMjlweDtcbn0iXX0= */