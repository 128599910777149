/* .wrapper {
  position: fixed;
  width: calc(100% - var(--st-spacer-xl));
  z-index: var(--st-zindex-mid);
  border-bottom: var(--st-border-width) solid var(--st-border-color);
  background-color: var(--st-page-bg);
  padding: var(--st-spacer-md) var(--st-spacer-mlg) var(--st-spacer-md) var(--st-spacer-mlg);
} */
.wrapper:global(.st_subnav) {
  position: fixed;
  width: 100%;
  z-index: var(--st-zindex-mid);
  background-color: var(--st-page-bg);
  padding: var(--st-spacer-md) var(--st-spacer-mlg) var(--st-spacer-md) var(--st-spacer-md);
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9JbWFnZURldGFpbHMvUGFnZU5hdmlnYXRpb24vUGFnZU5hdmlnYXRpb24ubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBU0E7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLyogLndyYXBwZXIge1xuICBwb3NpdGlvbjogZml4ZWQ7XG4gIHdpZHRoOiBjYWxjKDEwMCUgLSB2YXIoLS1zdC1zcGFjZXIteGwpKTtcbiAgei1pbmRleDogdmFyKC0tc3QtemluZGV4LW1pZCk7XG4gIGJvcmRlci1ib3R0b206IHZhcigtLXN0LWJvcmRlci13aWR0aCkgc29saWQgdmFyKC0tc3QtYm9yZGVyLWNvbG9yKTtcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tc3QtcGFnZS1iZyk7XG4gIHBhZGRpbmc6IHZhcigtLXN0LXNwYWNlci1tZCkgdmFyKC0tc3Qtc3BhY2VyLW1sZykgdmFyKC0tc3Qtc3BhY2VyLW1kKSB2YXIoLS1zdC1zcGFjZXItbWxnKTtcbn0gKi9cblxuLndyYXBwZXI6Z2xvYmFsKC5zdF9zdWJuYXYpIHtcbiAgcG9zaXRpb246IGZpeGVkO1xuICB3aWR0aDogMTAwJTtcbiAgei1pbmRleDogdmFyKC0tc3QtemluZGV4LW1pZCk7XG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXN0LXBhZ2UtYmcpO1xuICBwYWRkaW5nOiB2YXIoLS1zdC1zcGFjZXItbWQpIHZhcigtLXN0LXNwYWNlci1tbGcpIHZhcigtLXN0LXNwYWNlci1tZCkgdmFyKC0tc3Qtc3BhY2VyLW1kKTtcbn0gIl19 */