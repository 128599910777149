.metadata {
  margin: 0;
}

.metadata dd,
.metadata dt {
  margin: 0;
  padding: 0;
  display: inline;
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9JbWFnZURldGFpbHMvTWV0YWRhdGFEZXNjcmlwdGlvbi9NZXRhZGF0YURlc2NyaXB0aW9uLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTs7O0FBR0Y7QUFBQTtFQUVFO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5tZXRhZGF0YSB7XG4gIG1hcmdpbjogMDtcbn1cblxuLm1ldGFkYXRhIGRkLFxuLm1ldGFkYXRhIGR0IHtcbiAgbWFyZ2luOiAwO1xuICBwYWRkaW5nOiAwO1xuICBkaXNwbGF5OiBpbmxpbmU7XG59XG4iXX0= */