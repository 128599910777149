.listItem > div {
  width: var(--st-header-height);
  height: var(--st-header-height);
  border-radius: var(--st-border-radius);
}

.groupHover:hover {
  background-color: var(--st-content-bg);
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3RtcC9idWlsZC9zcmMvY29tcG9uZW50cy9CdXR0b25zL1ZhcmlhbnQvVmFyaWFudEJ1dHRvbi5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNJIiwic291cmNlc0NvbnRlbnQiOlsiLmxpc3RJdGVtID4gZGl2IHtcbiAgd2lkdGg6IHZhcigtLXN0LWhlYWRlci1oZWlnaHQpO1xuICBoZWlnaHQ6IHZhcigtLXN0LWhlYWRlci1oZWlnaHQpO1xuICBib3JkZXItcmFkaXVzOiB2YXIoLS1zdC1ib3JkZXItcmFkaXVzKTtcbn1cblxuLmdyb3VwSG92ZXI6aG92ZXIge1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXN0LWNvbnRlbnQtYmcpO1xufVxuXG4iXX0= */