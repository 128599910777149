.position {
  position: relative;
}

.message {
  right: 0;
  top: var(--st-spacer-md);
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-sm);
  color: var(--st-color-white);
  padding: var(--st-spacer-xs) var(--st-spacer-sm);
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  text-wrap: nowrap;
}
