.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--st-border-color);
}

.wrapper--fill {
  width: 100%;
  height: 100%;
  position: absolute;
}

.breakword {
  overflow: hidden;
  overflow-wrap: anywhere;
}

.transparent_bg {
  background-image: linear-gradient(45deg, var(--st-color-asphalt) 25%, transparent 25%),
  linear-gradient(-45deg, var(--st-color-asphalt) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--st-color-asphalt) 75%),
  linear-gradient(-45deg, transparent 75%, var(--st-color-asphalt) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  background-color: var(--st-color-gray)
}

.image {
  animation: fadeIn ease 1s;
  border-radius: var(--st-border-radius);
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}