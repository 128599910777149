/* .wrapper {
  position: fixed;
  width: calc(100% - var(--st-spacer-xl));
  z-index: var(--st-zindex-mid);
  border-bottom: var(--st-border-width) solid var(--st-border-color);
  background-color: var(--st-page-bg);
  padding: var(--st-spacer-md) var(--st-spacer-mlg) var(--st-spacer-md) var(--st-spacer-mlg);
} */

.wrapper:global(.st_subnav) {
  position: fixed;
  width: 100%;
  z-index: var(--st-zindex-mid);
  background-color: var(--st-page-bg);
  padding: var(--st-spacer-md) var(--st-spacer-mlg) var(--st-spacer-md) var(--st-spacer-md);
} 