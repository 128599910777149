:global(.st_modal) .iframe {
  height: calc(100% - 62px);
  width: 100%;
  border: 0;
}

.iframe_modal {
  margin: var(--st-spacer-xl);
  border: var(--st-spacer-xs) solid var(--st-border-color);
  border-radius: var(--st-border-radius);
}

:global(.st_modal) .iframe_modal_st_modal__title {
  padding: var(--st-spacer-md);
}

:global(.st_modal) .iframe_modal_st_modal__subtitle {
  padding: var(--st-spacer-sm) var(--st-spacer-md);
  border-top: var(--st-border-width) solid var(--st-border-color);
  border-bottom: var(--st-border-width) solid var(--st-border-color);
}

.image_ref {
  max-width: 100%;
}
.image_ref_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(var(--st-spacer-smd) - var(--st-spacer-xs)) calc(var(--st-spacer-mlg) - var(--st-spacer-xs))
    calc(var(--st-spacer-smd) - var(--st-spacer-xs)) calc(var(--st-spacer-mlg));
}